<template>
  <div class="main-content">
    <div class="content">
        <div class="left">
            <div class="leftItem" :style="isNavLeft==index?{backgroundColor:isSkin}:''" v-for="(item,index) in item" :key="index" @click.stop="leftMethod(index)">
                <router-link class="link" :style="isNavLeft==index?{color:'#FFF'}:''" :to="item.path">{{item.title}}</router-link>
            </div>
        </div>
        <div class="right">
            <router-view></router-view>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    components:{},
    data() {
        return {
            cur:0,
            item:[{title:'个人中心',path:'/user'},
                {title:'我的收藏',path:'/myCollection'},
                {title:'教师认证',path:'/myCertification'},
                {title:'我的地址',path:'/myAddress'},
                {title:'样书申请',path:'/myApply'},
                {title:'安全设置',path:'/mySetting'}
            ],
            index:{cur:0}
        }
    },
    created(){
        this.$store.commit('changClick',1)
        var skin = sessionStorage.getItem('Skin')
        var zskin = sessionStorage.getItem('ZSkin')
        if (skin) {
            this.$store.commit('changSkin',skin)
        }
        if (zskin) {
            this.$store.commit('changZSkin',zskin)
        }
        this.leftMethod(this.$store.state.isLeft)
    },
    computed:{
        isSkin(){
            return this.$store.state.isSkin
        },
        isNavLeft(){
            return this.$store.state.isLeft
        }
    },
    methods:{
        /**菜单点击按钮**/
        leftMethod(index){
            this.$store.commit('changLeft',index)
            this.$store.commit('changClick',1)
            sessionStorage.setItem('navLeft',index)
        }
    }
}
</script>

<style scoped lang="scss">
.main-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    .content{
        width: 1200px;
        display: flex;
        justify-items: center;
        align-items: flex-start;
        margin-top: 50px;
        margin-bottom: 50px;
        .left{
            width: 285px;
            background-color: #FCFCFC;
            padding-bottom: 20px;
            .leftItem{
                width: 285px;
                height: 42px;
                margin-top: 10px;
                .link{
                    text-decoration: none;
                    font-size: 16px;
                    line-height: 42px;
                    color: #333333;
                    padding-left: 32px;
                    width: 252px;
                    height: 100%;
                    display: flex;
                }
            }
        }
        .right{
            width: 895px;
            margin-left: 20px;
        }
    }
}
</style>